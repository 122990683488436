<template>
    <v-dialog v-model="dialog" width="500" persistent :disabled="disabled">
        <template v-slot:activator="{ on, attrs }">
            <div v-bind="attrs" v-on="on">
                <slot></slot>
            </div>
        </template>
        <v-card>
            <v-card-title>Video Library {{ isCreate ? 'upload' : 'delete' }}</v-card-title>
            <v-card-text>
                <!-- CRUD: CREATE -->
                <template v-if="isCreate">
                    <template v-if="!start">
                        <p>
                            Please select if you would want to keep the uploaded video among the files or just want to move the video to the library and remove
                            it from the files.
                        </p>
                        <v-radio-group v-model="deleteAfetrUpload" hide-details>
                            <v-radio label="Remove from files" :value="true"></v-radio>
                            <v-radio label="Keep among files" :value="false"></v-radio>
                        </v-radio-group>
                    </template>
                    <template v-if="start">
                        <p>Video upload has started, see the progress below:</p>
                        <template v-if="vimeoUploadProgress < 100 || !video">
                            <v-progress-linear indeterminate></v-progress-linear>
                            <div v-if="videos.length > 1" class="mt-2 text-center">Video uploaded: {{ vimeoVideosFinised }} / {{ videos.length }}</div>
                        </template>
                        <div
                            v-if="vimeoUploadProgress === 100 && video"
                            style="width: 100%; height: 100%"
                            class="d-flex flex-column justify-center align-center">
                            <v-icon large color="success">done</v-icon>
                            <div :class="{ 'mb-4': videos.length === 1 }">
                                <span v-if="videos.length > 1">ALL</span>
                                DONE
                            </div>
                            <vimeo-embed-code-btn v-if="videos.length === 1 && video" :video="video"></vimeo-embed-code-btn>
                        </div>
                    </template>
                </template>
                <!-- CRUD: DELETE -->
                <template v-else>
                    <p>Video delete has started, see the progress below:</p>
                    <template v-if="vimeoUploadProgress < 100">
                        <v-progress-linear indeterminate></v-progress-linear>
                        <div v-if="videos.length > 1" class="mt-2 text-center">Video deleted: {{ vimeoVideosFinised }} / {{ videos.length }}</div>
                    </template>
                    <div v-if="vimeoUploadProgress === 100" style="width: 100%; height: 100%" class="d-flex flex-column justify-center align-center">
                        <v-icon large color="success">done</v-icon>
                        <div>
                            <span v-if="videos.length > 1">ALL</span>
                            DONE
                        </div>
                    </div>
                </template>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="secondary" text @click="dismiss">Close</v-btn>
                <v-btn v-if="isCreate && start" color="primary" text @click="dialog = false" :to="{ name: 'vimeo-videos' }">Go to Videos</v-btn>
                <v-btn @click="crudCall" text color="primary" v-if="isCreate && !start">Ok</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import { enumsData } from '@/mixins/enums'
import { delay } from '@/apis'
import { get } from 'lodash'

const VimeoEmbedCodeBtn = () => import('../components/VimeoEmbedCodeBtn.vue')

export default {
    name: 'VimeoUploadDialog',
    mixins: [enumsData],
    components: { VimeoEmbedCodeBtn },
    data() {
        return {
            vimeoUploadProgress: 0,
            vimeoVideosFinised: 0,
            video: null,
            deleteAfetrUpload: true,
            start: false,
            dialog: false
        }
    },
    props: {
        videos: { type: Array, required: true },
        crud: { type: String, default: 'create' },
        disabled: { type: Boolean, default: false }
    },
    methods: {
        async crudCall() {
            this.start = true
            this.vimeoUploadProgress = 0
            this.vimeoVideosFinised = 0
            const max = this.videos.length
            const fids = this.videos.map(video => video.fid)
            const uris = this.videos.map(video => video.uri)
            let taskId = null
            try {
                if (this.isCreate) {
                    const { data } = await this.$axios.post(`/spaces/${this.$route.params.sid}/upload_videos`, {
                        fids,
                        delete_from_nuvolos: this.deleteAfetrUpload
                    })
                    taskId = data.tkid
                } else {
                    const { data } = await this.$axios.post(`/spaces/${this.$route.params.sid}/delete_videos`, { uris })
                    taskId = data.tkid
                }
                let task = await this.$axios.get(`/tasks/${taskId}`)
                while (!task.data.finished) {
                    await delay(2000)
                    task = await this.$axios.get(`/tasks/${taskId}`)
                    this.vimeoVideosFinised = task.data.meta_data.finished
                    this.vimeoUploadProgress = Math.round((this.vimeoVideosFinised / max) * 100)
                }
                this.video = get(task, 'data.meta_data.videos[0]')
            } catch (error) {
                console.log(error)
                this.$store.dispatch('showSnackBar', {
                    snackBarText: `Something went wrong during upload.`,
                    snackBarTimeout: 10000,
                    snackBarIcon: 'error'
                })
            }
        },
        dismiss() {
            this.$emit('dismissed')
            this.dialog = false
        }
    },
    computed: {
        isCreate() {
            return this.crud === 'create'
        }
    },
    watch: {
        dialog(open) {
            if (open && !this.isCreate) {
                // delete
                this.crudCall()
            } else {
                this.start = false
            }
        }
    }
}
</script>
